<template>
  <div
    class="ecommerce-application"
    :class="!IS_LOGIN ? ['container', 'pt-5']:null "
    style="height: inherit"
  >

    <b-row>
      <b-col lg="3">
        <!-- Prodcuts -->

        <!-- Pagination -->
        <!-- <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.pageNumber.value"
            align="center"
            last-number
            :total-rows="getProperties.total_count"
            :per-page="filters.pageLength.value"
            prev-class="prev-item"
            next-class="next-item"
            @input="filterProducts"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section> -->

        <!-- Sidebar -->
        <shop-left-filter-sidebar
          :filters="filters_side"
          :filter-options="filterOptions"
          :category="queryCategory"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @filterForm="form = $event"
        />
      </b-col>
      <b-col lg="9">    <!-- ECommerce Header -->
        <section id="ecommerce-header">
          <div class="row">
            <div class="col-sm-12">
              <div class="ecommerce-header-items">
                <div class="result-toggler">
                  <feather-icon
                    icon="MenuIcon"
                    class="d-block d-lg-none"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                  <div class="search-results">
                    {{ products_all.length }}  sonuç bulundu
                  </div>
                </div>
                <div class="view-options d-flex">

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon mr-2"
                    @click="$router.push({ name: 'checkout' })"
                  >
                    <span class="mr-1">Sepete Git</span>
                    <feather-icon icon="ShoppingCartIcon" />
                  </b-button>
                  <!-- Sort Button -->
                  <b-dropdown
                    v-if="false"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="sortBy.text"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item
                      v-for="sortOption in sortByOptions"
                      :key="sortOption.value"
                      @click="sortBy=sortOption"
                    >
                      {{ sortOption.text }}
                    </b-dropdown-item>
                  </b-dropdown>

                  <!-- Item View Radio Button Group  -->
                  <b-form-radio-group
                    v-model="itemView"
                    class="ml-1 list item-view-radio-group"
                    buttons
                    size="sm"
                    button-variant="outline-primary"
                  >
                    <b-form-radio
                      v-for="option in itemViewOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      <feather-icon
                        :icon="option.icon"
                        size="18"
                      />
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Overlay -->
        <div class="body-content-overlay" />

        <!-- Searchbar -->

        <div class="ecommerce-searchbar mt-1">
          <b-row>
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Ürün ismi"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <b-overlay :show="showS">
          <section :class="itemView">

            <b-card
              v-for="product in products_all"
              :key="product.id"
              class="ecommerce-card"
              no-body
            >
              <div class="item-img text-center pt-0">
                <b-img
                  :alt="`${product.name}-${product.id}`"
                  fluid
                  style="width: 100%; height: 250px; object-fit: contain;"
                  class="card-img-top"
                  :src="product.photo.thumb_url"
                />
              </div>

              <!-- Product Details -->
              <b-card-body>
                <div class="item-wrapper">
                  <!-- <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div> -->
                  <div>
                    <h6>
                      {{ product.category_name }}
                    </h6>
                  </div>
                  <div>
                    <h6 class="item-price">
                      ₺{{ product.list_price }}
                    </h6>
                  </div>
                </div>
                <h6 class="item-name">
                  <b-link
                    class="text-body"
                  >
                    {{ product.name }}
                  </b-link>
                  <b-card-text class="item-company">
                    Ürün Kodu:
                    {{ product.code }}
                  </b-card-text>
                </h6>
                <b-card-text class="item-description">
                  <!-- {{ product.description }} -->
                  <!-- Vergi Oranı: {{ product.vat_rate }}<br>
                Stok Miktarı: {{ Number(product.stock_count).toFixed(0) }}<br> -->
                  Ürün Birimi: {{ product.unit }}
                  <br>
                  Stok kodu: {{ product.code }}
                </b-card-text>
              </b-card-body>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      ₺{{ product.list_price }}
                    </h4>
                  </div>
                </div>
                <b-button
                  v-if="getCartProducts.findIndex(r=> r.id === product.id) === -1"
                  variant="primary"
                  tag="a"
                  class="btn-cart"
                  @click="handleCartActionClick(product, true)"
                >
                  <feather-icon
                    icon="ShoppingCartIcon"
                    class="mr-50"
                  />
                  <span>{{ 'Sepete ekle' }}</span>
                </b-button>
                <b-button
                  v-else
                  variant="danger"
                  tag="a"
                  class="btn-cart"
                  @click="handleCartActionClick(product, false)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span>{{ 'Sepetten Çıkar' }}</span>
                </b-button>
              </div>
            </b-card>
          </section>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import products from './products'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BOverlay,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    // BPagination,

    // SFC
    ShopLeftFilterSidebar,
  },
  data() {
    return {
      products,
      products_all: [],
      showS: false,
      mqShallShowLeftSidebar: false,
      filterOptions: {
        priceRangeDefined: [
          { text: 'Hepsi', value: [0, 100000000000] },
          { text: '<= 10', value: [0, 10] },
          { text: '10 - 100', value: [10, 100] },
          { text: '100 - 500', value: [100, 500] },
          { text: '>= 500', value: [500, 100000000000] },
        ],
        categories: [],
      },
      itemView: 'grid-view',
      sortBy: { text: 'Featured', value: 'featured' },
      sortByOptions: [
        { text: 'Featured', value: 'featured' },
        { text: 'Lowest', value: 'price-asc' },
        { text: 'Highest', value: 'price-desc' },
      ],
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      filters_side: {
        q: '',
        priceRangeDefined: null,
        priceRange: [0, 100],
        categories: [],
        brands: [],
        ratings: null,
        page: 1,
        perPage: 9,
      },
      searchTerm: '',
      form: {},
      queryCategory: '',
    }
  },
  computed: {
    ...mapGetters('product', ['getProductsData', 'getProperties', 'getCartProducts']),
    ...mapGetters('auth', ['IS_LOGIN']),
    productQueryId() {
      return this.$route.query.product
    },
  },
  watch: {
    form: {
      handler() {
        this.changeForm()
      },
      deep: true,
    },
    searchTerm(to) {
      this.filterProducts(`name=${to}`)
    },
    showS(to) {
      if (this.$route.query.category && !to && !this.queryCategory) {
        this.queryCategory = this.$route.query.category
      }
    },
  },
  created() {
    this.filterProducts().then(() => {
      if (this.productQueryId) {
        const product = this.products_all.find(x => String(x.id) === String(this.productQueryId))
        this.handleCartActionClick(product, true)
      }
    })
    this.getCategories()
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    ...mapMutations('product', ['SET_CART']),
    filterProducts(flt = null) {
      this.showS = true
      return this.getProducts(flt).then(() => {
        this.products_all = this.getProductsData
        this.showS = false
      })
    },
    getCategories() {
      this.$http.get('parasut/product/categories/').then(res => {
        this.filterOptions.categories = res.data.data.map(x => ({
          name: x.attributes.name,
          id: x.id,
        }))
      })
    },
    changeForm() {
      this.products_all = []
      Object.entries(this.form).forEach(([key, value]) => {
        this.getProductsData.forEach(el => {
          if (key === 'category_name' && value) {
            if (value.includes(el.category_name) && this.products_all.findIndex(x => x.id === el.id) === -1) {
              this.products_all.push(el)
            }
          }
          if ((key === 'list_price' || 'range') && value) {
            if (Number(el.list_price) >= Number(value[0]) && Number(el.list_price) <= Number(value[1])) {
              if (this.products_all.findIndex(x => x.id === el.id) === -1) {
                this.products_all.push(el)
              }
            }
          }
        })
      })
    },
    handleCartActionClick(product, set) {
      this.SET_CART({ val: product, set })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce";

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
