<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              Filtrele
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>

          <!-- Multi Range -->
          <div class="multi-range-price">
            <h6 class="filter-title mt-0">
              Fiyat Aralığı
            </h6>
            <b-form-radio-group
              v-model="filters.priceRangeDefined"
              class="price-range-defined-radio-group"
              stacked
              :options="filterOptions.priceRangeDefined"
              @input="changeEvent('range', $event)"
            />
          </div>

          <!-- Price Slider -->
          <div
            v-if="false"
            class="price-slider"
          >
            <h6 class="filter-title">
              Fiyat Aralığı
            </h6>
            <vue-slider
              v-model="filters.priceRange"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              @change="changeEvent('list_price', $event)"
            />
          </div>

          <!-- Categories -->
          <div
            v-if="filters.categories"
            class="product-categories"
          >
            <h6 class="filter-title">
              Kategori
            </h6>
            <!-- <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.categories"
              @change="changeEvent('category_name', $event)"
            /> -->
            <div class="categories-radio-group">
              <b-form-checkbox
                v-for="(item, i) in filterOptions.categories"
                :id="'check-'+item.id"
                :key="i"
                :ref="'check-'+item.id"
                @change="setCategories($event, item.name)"
              >
                {{ item.name }}
              </b-form-checkbox>
            </div>
          </div>

        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRadioGroup, BFormCheckbox,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BCard,
    BFormCheckbox,
    // 3rd Party
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    category: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        range: null,
        list_price: null,
        category_name: [],
      },
    }
  },
  watch: {
    category(to) {
      if (to) {
        document.getElementById(`check-${this.category}`).click()
      }
    },
  },
  // created() {
  //   if (this.category) {
  //     console.log(this.category)
  //     this.form.category_name.push(this.category)
  //     // console.log(this.$refs[`check-${this.category}`].click())
  //   }
  // },
  // mounted() {
  //   console.log(this.category)
  //   if (this.category) {
  //     document.getElementById(`check-${this.category}`).click()
  //   }
  // },
  methods: {
    changeEvent(key, e) {
      this.form[key] = e
      this.$emit('filterForm', this.form)
    },
    setCategories(value, key) {
      if (value) {
        this.form.category_name.push(key)
      } else {
        const index = this.form.category_name.findIndex(x => x === key)
        this.form.category_name.splice(index, 1)
      }
      this.$emit('filterForm', this.form)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
